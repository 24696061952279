<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Información del usuario</b-card-title>
      <b-card-sub-title>En este modulo puedes cambiar tus datos de perfil</b-card-sub-title>
      <hr>
      <validation-observer
        ref="UsersForm"
      >
        <vue-profile
          v-if="userData"
          :profile-data="userData"
          @input-email="setTipoCargo"
          @input-tipo-cargo-id="setTipoCargo"
          @input-roles="setTipoCargo"
          @input-first-name="setTipoCargo"
          @input-last-name="setTipoCargo"
          @input-status-id="setTipoCargo"
        />
      </validation-observer>
      <footer>
        <div class="w-100">
          <b-button
            v-if="$can('update', 'profile')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            @click="save"
          >
            Actualizar
          </b-button>
        </div>
      </footer>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, integer, alpha, alphaSpaces, email, ext,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/users/user/userStoreModule'
import IndexProfile from '@/views/users/profile/ProfileEdit.vue'

export default {
  name: 'Traveler',
  components: {
    ValidationObserver,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    'vue-profile': IndexProfile,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      // Users
      emailUsers: null,
      tipo_cargo_id: [],
      roles: [],

      // profile
      first_name: null,
      last_name: null,

      userData: null,
      // validation rules
      required,
      integer,
      alpha,
      alphaSpaces,
      email,
      ext,
    }
  },
  mounted() {
    this.setDataUser()
  },
  methods: {
    setDataUser() {
      const userId = JSON.parse(localStorage.getItem('userData'))
      store.dispatch('app-user/fetchUser', { id: userId.id })
        .then(response => {
          this.userData = response.data.userData
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.userData = undefined
          }
        })
    },
    setTipoCargo(value) {
      this.[value.key] = value.info
    },
    save() {
      this.$refs.UsersForm.validate().then(success => {
        if (success) {
          const rolId = []
          if (this.roles.length > 0) {
            rolId.push(this.roles[0].id)
          } else {
            rolId.push(this.roles.id)
          }
          const dataUser = {
            id: this.userData.id,
            email: this.emailUsers,
            roles: rolId,
            first_name: this.first_name,
            last_name: this.last_name,
            status_id: this.status_id.id,
            tipo_cargo_id: this.tipo_cargo_id.id,
          }
          this.updateUsers(dataUser)
        }
      })
    },
    updateUsers(data) {
      this.$http.put(`/v1/users/single/profile/`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.close()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>

<style>

</style>
